import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

class NotFound extends Component {
  render() {
    return [
      <Helmet
        key="0"
        title="MVoter.com - Page not found"
        meta={[{ name: 'description', content: 'Page not found' }]}
      />,
      <div key="1" style={{ position: 'relative', width: '100%', height: '100vh' }}>
        <img src="../../images/404.svg" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} width="800" height="457" alt="404" />
      </div>
    ];
  }
}

export default connect(
  () => ({}),
  {}
)(NotFound);
