const LOAD = 'GET_SURVEY_QUESTIONS_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_QUESTIONS_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_QUESTIONS_LOAD_FAIL';

const LOAD2 = 'GET_WIDGET_QUESTIONS_LOAD';
const LOAD_SUCCESS2 = 'GET_WIDGET_QUESTIONS_LOAD_SUCCESS';
const LOAD_FAIL2 = 'GET_WIDGET_QUESTIONS_LOAD_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.questions,
        data2: action.result.widgets
      };
    case LOAD_SUCCESS2:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [],
        data2: action.result.widgets,
        data3: action.result.ownwidgets
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getQuestions(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/question?id=${id}`)
  };
}

export function getQuestionsLink(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/questionlink?id=${id}`)
  };
}

export function getWidget(id) {
  return {
    types: [LOAD2, LOAD_SUCCESS2, LOAD_FAIL2],
    promise: ({ client }) => client.get(`/v1/survey/widget?id=${id}`)
  };
}
