import React, { Component } from 'react';

class More extends Component {
  render() {
    const styles = require('./More.less');

    return (
      <div id={ styles.more }>
        <h2>Mvoter — это эффективный и простой в настройке онлайн-сервис, помогающий собирать обратную связь от ваших клиентов.
          Нашу платформу можно использовать в разных отраслях бизнеса. Ее легко адаптировать под потребности конкретной компании.</h2>

        <p>Используя Mvoter, вы сможете создавать онлайн-опросники, и всегда будете хорошо осведомлены о мнениях клиентов о ваших продуктах или услугах.</p>

        <h3>Услуги онлайн платформы для создания моментальной обратной связи с Клиентами и Целевой Аудиторией</h3>

        <p>Чтобы создать опрос, который поможет развивать компанию в правильном направлении, потребуется от 20 минут. Время на настройку системы зависит от масштабов бизнеса. При необходимости консультанты Mvoter помогут разобраться в сервисе — поддержка наших клиентов осуществляется в круглосуточном режиме и без выходных.</p>

        <p>Анкеты-опросники для клиентов, созданные посредством Mvoter, обеспечат вам ряд возможностей:</p>
        <ul>
          <li>Моментальная обратная связь. Наше приложение для отзывов позволяет видеть мнения, вопросы и жалобы ваших клиентов сразу после заполнения опросника-анкеты.</li>
          <li>Возможность улучшения клиентского опыта. Замечания и пожелания от людей, заинтересованных в ваших продуктах, покажут слабые места бизнеса и откроют точки роста, о которых вы могли даже не подозревать.</li>
          <li>Возможность быстрого старта. Предлагаем более 100 готовых шаблонов. С их помощью можно быстро создать опросник для любого сценария, от оценки удовлетворенности до определения индекса доверия к компании.</li>
          <li>Оперативная работа с негативом. Получив отрицательную оценку, вы сможете моментально позвонить оставившему ее клиенту. Это позволит узнать подробнее, что его не устроило в качестве товара или услуги, и быстро устранить проблему.</li>
        </ul>

        <p>Провести опрос можно как среди реальных клиентов компании, так и среди целевой аудитории, которая еще не покупала ваши товары или услуги.</p>

        <h3>Создать онлайн-анкету опросник для сбора и анализа обратной связи от Клиентов</h3>
        <p>Обратная связь от клиентов важна для развития любого бизнеса. Чем лучше вы знаете сильные и слабые стороны компании, тем эффективнее будет каждое ваше действие. С Mvoter можно создать онлайн опросник для сбора и анализа следующих параметров:</p>
        <ul>
          <li>Удовлетворенность сервисом. Показатель помогает получить объективную информацию о качестве сервиса, который клиенты получают при взаимодействии с сотрудниками компании. Вы сможете узнать, какие именно аспекты необходимо улучшить для получения превосходства над конкурентами.</li>
          <li>Удовлетворенность товаром или продуктом. Информация о том, какие категории клиентов остаются недовольны после покупки и почему, поможет доработать продукт до идеала.</li>
          <li>Удовлетворенность заведением или компанией. Отслеживание репутации позволяет понимать, в правильном ли направлении развивается бизнес, и какие его слабые стороны затормаживают рост.</li>
          <li>Net Promoter Score (NPS). Так называется индекс доверия и готовности вашей ЦА рекомендовать товары или услуги компании другим людям. Работая над его повышением, вы обеспечите рост продаж за счет «сарафанного радио».</li>
          <li>Customer Effort Score (CES).  Показатель позволяет оценить, насколько сложно для клиента взаимодействовать с компанией при покупке продукта, оформлении возврата или получения помощи в решении проблемы. Упрощая эти аспекты, вы сможете повысить лояльность целевой аудитории к бренду.</li>
          <li>Customer Satisfaction Score (CSAT). Измерение общего индекса удовлетворенности клиентов помогает выявить скрытые проблемы и оперативно устранить их.</li>
        </ul>

        <p>Кроме того, можно организовать сбор любых других данных. Например, подготовить систему для отзывов сотрудников или анкету для опроса потребителей по какому-либо узкому вопросу.</p>

        <h4>Провести онлайн опрос потребителей товаров и услуг и собрать оценки, отзывы и аналитику для управления удовлетворенностью Клиентов</h4>
        <p>Система опросов, внедренная в работу вашей компании, позволит получать объективную аналитику об удовлетворенности клиентов сервисом, товарами или услугами компании. Отчеты формируются автоматически, их можно экспортировать в разных форматах.</p>

        <p>Решив создать онлайн-опрос, вы сможете узнать:</p>
        <ul>
          <li>что необходимо улучшить для повышения продаж;</li>
          <li>кто из сотрудников хорошо справляется со своими обязанностями, а кто нет;</li>
          <li>чего не хватает вашим клиентам для полной удовлетворенности товарами или услугами.</li>
        </ul>

        <p>Создавать специальный сайт для отзывов не придется — наша онлайн-платформа позволяет начать сбор обратной связи быстро и без технических сложностей.</p>

        <h5>Отрасли использования онлайн системы Mvoter: рестораны, банки, здравоохранение, торговля и общественный транспорт</h5>

        <p>Сервис для отзывов от клиентов Mvoter подходит для разных отраслей бизнеса, среди которых:</p>

        <ul>
          <li>Рестораны и кафе. Разместите QR-код на столиках в заведении или на бейджиках официантов, чтобы получать аналитические отчеты о работе сотрудников.</li>
          <li>Банки. QR-коды можно установить на рабочих столах и кассовых зонах, чтобы получать оперативную информацию об удовлетворенности клиентов.</li>
          <li>Торговля. Добавив в заметных для посетителей местах QR-коды для перехода на онлайн-опрос, вы получите объективные сведения об удовлетворенности клиентов каждой конкретной точки продаж.</li>
          <li>Здравоохранение. Установленные в регистратуре или на бейджиках персонала QR-коды помогут отслеживать компетентность и вежливость сотрудников частных и государственных клиник.</li>
          <li>Общественный транспорт. Разместив QR-коды в автобусах, поездах или самолетах вы будете всегда в курсе того, что о качестве перевозок думают реальные клиенты.</li>
        </ul>

        <p>QR-код удобен для клиентов. Отсканировав его камерой смартфона, человек сразу попадает на анкету-опросник, и может оставить отзыв с минимальными временными затратами.</p>

        <h6>Цены и тарифные планы на услуги онлайн платформы по сбору отзывов и управлению удовлетворенностью Клиента</h6>

        <p>Сбор обратной связи от клиентов с помощью Mvoter стоит от $99 в год. Точная цена зависит от выбранного тарифа — они различаются доступными возможностями.</p>

        <p>Запросите бесплатную демонстрацию, чтобы узнать подробнее о нашем сервисе или зарегистрируйтесь, чтобы создать первый онлайн-опросник уже сегодня.</p>
      </div>
    );
  }
}

export default More;
