import React, { Component } from 'react';

class Watching extends Component {
  render() {
    const styles = require('./Watching.less');
    return (
      <section className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <video id="mvoterVideo" width="100%" autoPlay loop muted>
              <source src="./images/footer.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
    );
  }
}

export default Watching;
