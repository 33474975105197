const EXPORT = 'EXPORT_SURVEY_SUBJECT_LOAD';
const EXPORT_SUCCESS = 'EXPORT_SURVEY_SUBJECT_LOAD_SUCCESS';
const EXPORT_FAIL = 'EXPORT_SURVEY_SUBJECT_LOAD_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function exportQr(id, data) {
  return {
    types: [EXPORT, EXPORT_SUCCESS, EXPORT_FAIL],
    promise: ({ client }) => client.post('/v1/report/export', { id, data })
  };
}
