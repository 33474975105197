import React, { Component } from 'react';

class Feedback extends Component {
  render() {
    const styles = require('./Feedback.less');
    return (
      <section className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <div className={ styles.leftSide }>
              <h2>Получайте моментальные сигналы о каждом отзыве</h2>
              <p>На Ваш смартфон мгновенно будут поступать все негативные отзывы, что позволит Вам оперативно контролировать и управлять удовлетворенностью ваших клиентов.</p>
            </div>
            <div className={ styles.rightSide }>
              <img src="./images/feedback.svg" width="554" height="445" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Feedback;
