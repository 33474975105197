import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { trigger } from 'redial';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { AppContainer as HotEnabler } from 'react-hot-loader';
import { getStoredState } from 'redux-persist';
import localForage from 'localforage';
import createStore from 'redux/create';
import apiClient from 'helpers/apiClient';
import routes from 'routes';
import asyncMatchRoutes from 'utils/asyncMatchRoutes';
import { RouterTrigger } from 'components';
import NProgress from 'nprogress';
import ReactGA from 'react-ga';

const persistConfig = {
  key: 'root',
  storage: localForage
};
const dest = document.getElementById('root');
const client = apiClient();

ReactGA.initialize('G-LGFQ45S2ZK');

(async () => {
  const preloadedState = await getStoredState(persistConfig);
  const history = createBrowserHistory();
  const store = createStore({
    history,
    data: {
      ...preloadedState,
      ...window.__INITIAL_STATE__
    },
    helpers: {
      client
    },
    persistConfig
  });

  const triggerHooks = async (_routes, pathname) => {
    if (pathname !== '/') {
      NProgress.start();
    }

    const { components, match, params } = await asyncMatchRoutes(_routes, pathname);
    const triggerLocals = {
      client,
      store,
      match,
      params,
      history,
      location: history.location
    };

    await trigger('inject', components, triggerLocals);

    if (window.__PRELOADED__) {
      delete window.__PRELOADED__;
    } else {
      await trigger('fetch', components, triggerLocals);
    }
    await trigger('defer', components, triggerLocals);

    if (pathname !== '/') {
      NProgress.done();
    }
  };

  const hydrate = _routes => {
    // const x = document.cookie.split(';');
    // let token = '';
    // for (let s = 0; s < x.length; s++) {
    //   if (x[s].split('=')[0] == 'MVOTER_SESSION') {
    //     token = x[s].split('=')[1];
    //     break;
    //   }
    // }
    const element = (
      <HotEnabler>
        <Provider store={store}>
          <Router history={history}>
            <RouterTrigger trigger={pathname => triggerHooks(_routes, pathname)}>{renderRoutes(_routes)}</RouterTrigger>
          </Router>
        </Provider>
      </HotEnabler>
    );

    if (dest.hasChildNodes()) {
      ReactDOM.hydrate(element, dest);
    } else {
      ReactDOM.render(element, dest);
    }
  };

  await Loadable.preloadReady();

  hydrate(routes);

  if (module.hot) {
    module.hot.accept('./routes', () => {
      const nextRoutes = require('./routes');
      hydrate(nextRoutes.__esModule ? nextRoutes.default : nextRoutes);
    });
  }

  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger
  }
  /*
  if (!__DEVELOPMENT__ && 'serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js', { scope: '/' });
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          switch (installingWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                console.log('New or updated content is available.');
              } else {
                console.log('Content is now available offline!');
              }
              break;
            case 'redundant':
              console.error('The installing service worker became redundant.');
              break;
            default:
          }
        };
      };
    } catch (error) {
      console.log('Error registering service worker: ', error);
    }

    await navigator.serviceWorker.ready;
    console.log('Service Worker Ready');
  }
  */
})();
