const LOAD = 'GET_SURVEY_ANSWER_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_ANSWER_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_ANSWER_LOAD_FAIL';
const SET = 'SET_SURVEY_ANSWER_LOAD';
const SET_SUCCESS = 'SET_SURVEY_ANSWER_LOAD_SUCCESS';
const SET_FAIL = 'SET_SURVEY_ANSWER_LOAD_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getAnswer(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/answer?id=${id}`)
  };
}

export function setAnswer(data) {
  return {
    types: [SET, SET_SUCCESS, SET_FAIL],
    promise: ({ client }) => client.post('/v1/survey/answer-old', { ...data })
  };
}
