const LOAD = 'AUTH_LOAD';
const LOAD_SUCCESS = 'AUTH_LOAD_SUCCESS';
const LOAD_FAIL = 'AUTH_LOAD_FAIL';
const SAVE = 'USER_SAVE';
const SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
const SAVE_FAIL = 'USER_SAVE_FAIL';
const LOGIN = 'USER_LOGIN';
const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const LOGIN_FAIL = 'USER_LOGIN_FAIL';
const REGISTER = 'USER_REGISTER';
const REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
const REGISTER_FAIL = 'USER_REGISTER_FAIL';
const FORGOT = 'USER_FORGOT';
const FORGOT_SUCCESS = 'USER_FORGOT_SUCCESS';
const FORGOT_FAIL = 'USER_FORGOT_FAIL';
const FEEDBACK = 'FEEDBACK';
const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
const FEEDBACK_FAIL = 'FEEDBACK_FAIL';
const CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL';
const LOGOUT = 'USER_LOGOUT';
const LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
const GET_HALYK_LINK = 'GET_SBER_LINK';
const GET_HALYK_LINK_SUCCESS = 'GET_SBER_LINK_SUCCESS';
const GET_HALYK_LINK_FAIL = 'GET_SBER_LINK_FAIL';
const GET_HALYK_CHECK_LINK = 'GET_SBER_CHECK_LINK';
const GET_HALYK_CHECK_LINK_SUCCESS = 'GET_SBER_CHECK_LINK_SUCCESS';
const GET_HALYK_CHECK_LINK_FAIL = 'GET_SBER_CHECK_LINK_FAIL';
const GET_INVOICE = 'GET_SBER_LINK';
const GET_INVOICE_SUCCESS = 'GET_SBER_LINK_SUCCESS';
const GET_INVOICE_FAIL = 'GET_SBER_LINK_FAIL';

const initialState = {
  loaded: false,
  user: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result.user
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOGIN:
      return {
        ...state,
        loggingIn: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loaded: true,
        user: action.result.user
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        loginError: action.error
      };
    case REGISTER:
      return {
        ...state,
        registeringIn: true
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registeringIn: false
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registeringIn: false,
        registerError: action.error
      };
    case FORGOT:
      return {
        ...state,
        forgot: true
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        forgot: false,
        user: null
      };
    case FORGOT_FAIL:
      return {
        ...state,
        forgot: false,
        forgotError: action.error
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/v1/user')
  };
}

export function save(data) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.post('/v1/user', { ...data })
  };
}

export function register(data) {
  return {
    types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
    promise: ({ client }) => client.post('/v1/register', { username: data.username, password: data.password })
  };
}

export function login(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: async ({ client }) => client.post('/v1/login', { username: data.username, password: data.password })
  };
}

export function forgot(data) {
  return {
    types: [FORGOT, FORGOT_SUCCESS, FORGOT_FAIL],
    promise: ({ client }) => client.post('/v1/forgot-password', { username: data.username })
  };
}

export function feedback(data) {
  return {
    types: [FEEDBACK, FEEDBACK_SUCCESS, FEEDBACK_FAIL],
    promise: ({ client }) => client.post('/v1/feedback', { ...data })
  };
}

export function changePassword(oldPassword, newPassword) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: ({ client }) => client.post('/v1/change-password', { password: oldPassword, newPassword })
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: async ({ client }) => client.post('/v1/logout')
  };
}

export function getHalykLink(subId, subType) {
  return {
    types: [GET_HALYK_LINK, GET_HALYK_LINK_SUCCESS, GET_HALYK_LINK_FAIL],
    promise: async ({ client }) => client.get(`/v1/pay/halykbank?subId=${subId}&subType=${subType}`)
  };
}

export function checkHalykOrder(subId) {
  return {
    types: [GET_HALYK_CHECK_LINK, GET_HALYK_CHECK_LINK_SUCCESS, GET_HALYK_CHECK_LINK_FAIL],
    promise: async ({ client }) => client.get(`/v1/pay/halykbank/check?orderId=${subId}`)
  };
}

export function getInvoice(invoiceBin, invoiceCompany, invoiceAddress, invoiceContact, tariffType, tariffValue) {
  return {
    types: [GET_INVOICE, GET_INVOICE_SUCCESS, GET_INVOICE_FAIL],
    promise: async ({ client }) => client.get(
      `/v1/pay/invoice?invoiceBin=${invoiceBin}&invoiceCompany=${invoiceCompany}&invoiceAddress=${invoiceAddress}&invoiceContact=${invoiceContact}&tariffType=${tariffType}&tariffValue=${tariffValue}`
    )
  };
}
