const LOAD = 'SURVEY_SETTINGS_LOAD';
const LOAD_SUCCESS = 'SURVEY_SETTINGS_LOAD_SUCCESS';
const LOAD_FAIL = 'SURVEY_SETTINGS_LOAD_FAIL';
const SAVE = 'SAVE_SURVEY_SETTINGS_LOAD';
const SAVE_SUCCESS = 'SAVE_SURVEY_SETTINGS_LOAD_SUCCESS';
const SAVE_FAIL = 'SAVE_SURVEY_SETTINGS_LOAD_FAIL';
const DOWNLOAD = 'DOWNLOAD_SURVEY_SETTINGS_LOAD';
const DOWNLOAD_SUCCESS = 'DOWNLOAD_SURVEY_SETTINGS_LOAD_SUCCESS';
const DOWNLOAD_FAIL = 'DOWNLOAD_SURVEY_SETTINGS_LOAD_FAIL';
const CHANGE_SETTING = 'CHANGE_SETTING';

const initialState = {
  loaded: false
};

function _changeSetting(id, state) {
  if (id === 'locale' && state.data[id]) {
    state.data.localeDefault = 'ru';
    state.data.localeData = '';
  }
  if (id === 'subjectqr') state.data.subjectqr >= 1 ? (state.data.subjectqr = 0) : (state.data.subjectqr = 1);
  else if (id === 'jalob') state.data.jalob >= 1 ? (state.data.jalob = 0) : (state.data.jalob = 3600);
  else if (id === 'email') state.data.email >= 1 ? (state.data.email = 0) : (state.data.email = 1);
  else if (id === 'emailcheck') state.data.email == 1 ? (state.data.email = 2) : (state.data.email = 1);
  else state.data[id] = !state.data[id];
  return state;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.settings
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case CHANGE_SETTING:
      return _changeSetting(action.id, { ...state });
    default:
      return state;
  }
}

export function changeSetting(id) {
  return {
    type: CHANGE_SETTING,
    id
  };
}

export function getSettings(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/settings?id=${id}`)
  };
}

export function setSettings(id, data) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.post('/v1/survey/settings', { id, data })
  };
}

export function downloadPdfAndQRCode(id, format) {
  return {
    types: [DOWNLOAD, DOWNLOAD_SUCCESS, DOWNLOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/download?id=${id}&format=${format}`)
  };
}
