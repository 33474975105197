const LOAD = 'GET_SURVEY_DESIGN_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_DESIGN_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_DESIGN_LOAD_FAIL';
const GET = 'GET_SURVEY_TEMPLATE_LOAD';
const GET_SUCCESS = 'GET_SURVEY_TEMPLATE_LOAD_SUCCESS';
const GET_FAIL = 'GET_SURVEY_TEMPLATE_LOAD_FAIL';
const SAVE = 'UPLOAD_SURVEY_TEMPLATE_LOAD';
const SAVE_SUCCESS = 'UPLOAD_SURVEY_TEMPLATE_LOAD_SUCCESS';
const SAVE_FAIL = 'UPLOAD_SURVEY_TEMPLATE_LOAD_FAIL';
const DELETE = 'REMOVE_SURVEY_TEMPLATE_LOAD';
const DELETE_SUCCESS = 'REMOVE_SURVEY_TEMPLATE_LOAD_SUCCESS';
const DELETE_FAIL = 'REMOVE_SURVEY_TEMPLATE_LOAD_FAIL';
const DELETE2 = 'REMOVE_SURVEY_IMAGE_LOAD';
const DELETE2_SUCCESS = 'REMOVE_SURVEY_IMAGE_LOAD_SUCCESS';
const DELETE2_FAIL = 'REMOVE_SURVEY_IMAGE_LOAD_FAIL';
const GET_IMAGE = 'GET_DESIGN_IMAGE_LOAD';
const GET_IMAGE_SUCCESS = 'GET_DESIGN_IMAGE_SUCCESS';
const GET_IMAGE_FAIL = 'GET_DESIGN_IMAGE_FAIL';
const SAVE_IMAGE = 'SAVE_DESIGN_IMAGE';
const SAVE_IMAGE_SUCCESS = 'SAVE_DESIGN_IMAGE_SUCCESS';
const SAVE_IMAGE_FAIL = 'SAVE_DESIGN_IMAGE_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.list
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case GET_IMAGE_SUCCESS:
      return {
        ...state,
        data2: action.result.list
      };
    default:
      return state;
  }
}

export function getTemplate() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/v1/survey/design/init')
  };
}

export function getTemplateById(id) {
  return {
    types: [GET, GET_SUCCESS, GET_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/design/template?id=${id}`)
  };
}

export function saveTemplate(data) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.post('/v1/survey/design/upload', { ...data })
  };
}

export function removeTemplate(id, template) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: ({ client }) => client.post(`/v1/survey/design/remove?id=${id}&template=${template}`)
  };
}

export function getImages() {
  return {
    types: [GET_IMAGE, GET_IMAGE_SUCCESS, GET_IMAGE_FAIL],
    promise: ({ client }) => client.get('/v1/survey/design/image')
  };
}

export function setImage(data) {
  return {
    types: [SAVE_IMAGE, SAVE_IMAGE_SUCCESS, SAVE_IMAGE_FAIL],
    promise: ({ client }) => client.post('/v1/survey/design/image', { ...data })
  };
}

export function removeImage(id) {
  return {
    types: [DELETE2, DELETE2_SUCCESS, DELETE2_FAIL],
    promise: ({ client }) => client.delete(`/v1/survey/design/image?id=${id}`)
  };
}
