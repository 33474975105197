const LOAD = 'GET_SURVEY_BRANCH_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_BRANCH_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_BRANCH_LOAD_FAIL';
const SAVE = 'SAVE_SURVEY_BRANCH_LOAD';
const SAVE_SUCCESS = 'SAVE_SURVEY_BRANCH_LOAD_SUCCESS';
const SAVE_FAIL = 'SAVE_SURVEY_BRANCH_LOAD_FAIL';
const EXPORT = 'EXPORT_SURVEY_BRANCH_LOAD';
const EXPORT_SUCCESS = 'EXPORT_SURVEY_BRANCH_LOAD_SUCCESS';
const EXPORT_FAIL = 'EXPORT_SURVEY_BRANCH_LOAD_FAIL';
const IMPORT = 'IMPORT_SURVEY_BRANCH_LOAD';
const IMPORT_SUCCESS = 'IMPORT_SURVEY_BRANCH_LOAD_SUCCESS';
const IMPORT_FAIL = 'IMPORT_SURVEY_BRANCH_LOAD_FAIL';
const INVITE = 'INVITE_SURVEY_BRANCH_LOAD';
const INVITE_SUCCESS = 'INVITE_SURVEY_BRANCH_LOAD_SUCCESS';
const INVITE_FAIL = 'INVITE_SURVEY_BRANCH_LOAD_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.branch,
        list: action.result.list,
        surveys: action.result.surveys
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getBranch(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/branch?id=${id}`)
  };
}

export function getBranchAll(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/branchall?id=${id}`)
  };
}

export function getBranchAllLink(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/branchalllink?id=${id}`)
  };
}

export function removeBranch(id) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/branchremove?id=${id}`)
  };
}

export function setBranch(id, data) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.post('/v1/survey/branch', { id, data })
  };
}

export function exportBranch(id) {
  return {
    types: [EXPORT, EXPORT_SUCCESS, EXPORT_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/branch/export?id=${id}`)
  };
}

export function importBranch(id, data) {
  return {
    types: [IMPORT, IMPORT_SUCCESS, IMPORT_FAIL],
    promise: ({ client }) => client.post('/v1/survey/branch/import', { id, data })
  };
}

export function inviteBranch(id) {
  return {
    types: [INVITE, INVITE_SUCCESS, INVITE_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/branch/invite?id=${id}`)
  };
}
