import { combineReducers } from 'redux';

import auth from './modules/auth';
import surveys from './modules/survey/survey';
import settings from './modules/survey/setting';
import branch from './modules/survey/branch';
import subject from './modules/survey/subject';
import design from './modules/survey/design';
import users from './modules/survey/user';
import analytics from './modules/survey/analytic';
import callCentre from './modules/survey/feedback';
import questions from './modules/survey/question';
import answer from './modules/survey/answer';
import additional from './modules/survey/additional';
import call from './modules/survey/call';
import message from './modules/survey/message';
import exportQr from './modules/survey/dashboard';

export default function createReducers(asyncReducers) {
  return {
    auth,
    survey: combineReducers({
      surveys,
      settings,
      branch,
      subject,
      design,
      users,
      analytics,
      callCentre,
      questions,
      answer,
      additional,
      call,
      message,
      exportQr
    }),
    ...asyncReducers
  };
}
