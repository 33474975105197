import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

class Branch extends Component {
  static propTypes = {
    onShowVideo: PropTypes.func
  };
  render() {
    const styles = require('./Branch.less');
    return (
      <Element name="link1" className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>В каких отраслях вы можете использовать MVoter?</h2>
            <ul className={ styles.branchList }>
              <li>
                <div className={ styles.picture }>
                  <img src="./images/play-button-128.png" className={ styles.playButton } onClick={ () => this.props.onShowVideo('534742387') } />
                  <img src="./images/industries/restaurant.svg" width="340" height="280" />
                </div>
                <div className={ styles.button } onClick={ () => this.props.onShowVideo('534742387') }>Ресторан / Кафе</div>
              </li>
              <li>
                <div className={ styles.picture }>
                  <img src="./images/play-button-128.png" className={ styles.playButton } onClick={ () => this.props.onShowVideo('534737791') } />
                  <img src="./images/industries/bank.svg" width="366" height="240" />
                </div>
                <div className={ styles.button } onClick={ () => this.props.onShowVideo('534737791') }>Банки</div>
              </li>
              <li>
                <div className={ styles.picture }>
                  <img src="./images/play-button-128.png" className={ styles.playButton } onClick={ () => this.props.onShowVideo('534747656') } />
                  <img src="./images/industries/trade.svg" width="340" height="280" />
                </div>
                <div className={ styles.button } onClick={ () => this.props.onShowVideo('534747656') }>Торговля</div>
              </li>
              <li>
                <div className={ styles.picture }>
                  <img src="./images/play-button-128.png" className={ styles.playButton } onClick={ () => this.props.onShowVideo('534738922') } />
                  <img src="./images/industries/doctor.svg" width="340" height="280" />
                </div>
                <div className={ styles.button } onClick={ () => this.props.onShowVideo('534738922') }>Здравоохранение</div>
              </li>
              <li>
                <div className={ styles.picture }>
                  <img src="./images/play-button-128.png" className={ styles.playButton } onClick={ () => this.props.onShowVideo('534743921') } />
                  <img src="./images/industries/goods.svg" width="366" height="250" />
                </div>
                <div className={ styles.button } onClick={ () => this.props.onShowVideo('534743921') }>Товары</div>
              </li>
              <li>
                <div className={ styles.picture }>
                  <img src="./images/play-button-128.png" className={ styles.playButton } onClick={ () => this.props.onShowVideo('534740518') } />
                  <img src="./images/industries/bus.svg" width="310" height="250" />
                </div>
                <div className={ styles.button } onClick={ () => this.props.onShowVideo('534740518') }>Общественный транспорт</div>
              </li>
            </ul>
          </div>
        </div>
      </Element>
    );
  }
}

export default Branch;
