import React from 'react';
import Loadable from 'react-loadable';

const Tariff = Loadable({
  loader: () => import('./Tariff' /* webpackChunkName: 'tariff' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Reliability = Loadable({
  loader: () => import('./Reliability' /* webpackChunkName: 'reliability' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const CallCenter = Loadable({
  loader: () => import('./CallCenter' /* webpackChunkName: 'callCenter' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const TypeSurvey = Loadable({
  loader: () => import('./TypeSurvey' /* webpackChunkName: 'typeSurvey' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Education = Loadable({
  loader: () => import('./Education' /* webpackChunkName: 'education' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Integration = Loadable({
  loader: () => import('./Integration' /* webpackChunkName: 'integration' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Faq = Loadable({
  loader: () => import('./Faq' /* webpackChunkName: 'faq' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const PayCheck = Loadable({
  loader: () => import('./PayCheck' /* webpackChunkName: 'payCheck' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const MysteryShopping = Loadable({
  loader: () => import('./MysteryShopping' /* webpackChunkName: 'mysteryShopping' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

export { Tariff, Reliability, CallCenter, TypeSurvey, Education, Integration, Faq, PayCheck, MysteryShopping };
