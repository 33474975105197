import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import { scroller } from 'react-scroll';
import Header from 'components/Header/Header';
import { About, Branch, Analytic, Template, Feedback, Tarif, Download, Faq, Watching, Contact } from 'components/Section';
import Footer from 'components/Footer/Footer';
import ModalVideo from 'react-modal-video';
import '../../theme/Video.css';
import Helmet from 'react-helmet';

class Home extends Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any),
    location: PropTypes.objectOf(PropTypes.any),
    user: PropTypes.shape({
      email: PropTypes.string
    })
  };
  state = {
    videoShow: false,
    videoId: '534737791'
  };
  showVideo = videoId => this.setState({ videoShow: !this.state.videoShow, videoId });
  componentDidMount() {
    if (this.props.location.search === '?subId=link1' || this.props.location.search === '?subId=link2'
      || this.props.location.search === '?subId=link3' || this.props.location.search === '?subId=link4'
      || this.props.location.search === '?subId=link6') {
      const r = this.props.location.search.split('=');
      scroller.scrollTo(r[1], {
        duration: 1500,
        delay: 100,
        smooth: true
      });
    }
  }
  render() {
    return (
      <StickyContainer>
        <Helmet
          htmlAttributes={{ lang: 'ru-RU' }}
          title="Super QR многофункциональный QR сервис для вашего бизнеса"
          meta={[{ name: 'description', content: 'Super QR многофункциональный QR сервис для вашего бизнеса' }]}
        />
        <Header location={this.props.location} />
        <Branch onShowVideo={this.showVideo} />
        <About />
        <Feedback />
        <Template />
        <Analytic />
        <Tarif />
        <Download />
        <Faq />
        <Contact />
        <Watching />
        <Footer subId="home" />
        <ModalVideo channel="vimeo" isOpen={this.state.videoShow} videoId={this.state.videoId} onClose={() => this.setState({ videoShow: false })} />
      </StickyContainer>
    );
  }
}

export default connect(
  state => ({
    user: state.auth.user
  }),
  {}
)(Home);
