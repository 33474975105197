const LOAD = 'GET_SURVEY_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_LOAD_FAIL';
const LOAD_PARENT = 'GET_PARENT_SURVEY_LOAD';
const LOAD_PARENT_SUCCESS = 'GET_PARENT_SURVEY_LOAD_SUCCESS';
const LOAD_PARENT_FAIL = 'GET_PARENT_SURVEY_LOAD_FAIL';
const LOAD2 = 'GET_SURVEYS_LOAD';
const LOAD2_SUCCESS = 'GET_SURVEYS_LOAD_SUCCESS';
const LOAD2_FAIL = 'GET_SURVEYS_LOAD_FAIL';
const SAVE = 'SAVE_SURVEY_LOAD';
const SAVE_SUCCESS = 'SAVE_SURVEY_LOAD_SUCCESS';
const SAVE_FAIL = 'SAVE_SURVEY_LOAD_FAIL';
const SAVE2 = 'SAVE_SURVEY2_LOAD';
const SAVE2_SUCCESS = 'SAVE_SURVEY2_LOAD_SUCCESS';
const SAVE2_FAIL = 'SAVE_SURVEY2_LOAD_FAIL';
const SAVE3 = 'SAVE_SURVEY3_LOAD';
const SAVE3_SUCCESS = 'SAVE_SURVEY3_LOAD_SUCCESS';
const SAVE3_FAIL = 'SAVE_SURVEY3_LOAD_FAIL';
const REMOVE = 'REMOVE_SURVEY_LOAD';
const REMOVE_SUCCESS = 'REMOVE_SURVEY_LOAD_SUCCESS';
const REMOVE_FAIL = 'REMOVE_SURVEY_LOAD_FAIL';
const TRASH = 'TRASH_SURVEY_LOAD';
const TRASH_SUCCESS = 'TRASH_SURVEY_LOAD_SUCCESS';
const TRASH_FAIL = 'TRASH_SURVEY_LOAD_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case LOAD2_SUCCESS:
      return {
        ...state,
        surveys: action.result
      };
    case LOAD_PARENT_SUCCESS:
      return {
        ...state,
        parentData: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getSurvey(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/receive?id=${id}`)
  };
}

export function getSurveyLink(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/receivelink?id=${id}`)
  };
}

export function getSurvey2(id) {
  return {
    types: [LOAD2, LOAD2_SUCCESS, LOAD2_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/receive?id=${id}`)
  };
}

export function getMenu(id) {
  return {
    types: [LOAD2, LOAD2_SUCCESS, LOAD2_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/menu?id=${id}`)
  };
}

export function getSurveyOld(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/receive-old?id=${id}`)
  };
}

export function getParentSurvey() {
  return {
    types: [LOAD_PARENT, LOAD_PARENT_SUCCESS, LOAD_PARENT_FAIL],
    promise: ({ client }) => client.get('/v1/survey/receive?id=parent')
  };
}

export function setSurveyName(id, val) {
  return {
    types: [SAVE3, SAVE3_SUCCESS, SAVE3_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/rename?id=${id}&title=${val}`)
  };
}

export function setQuestion(id, config, questions, widgets) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.post('/v1/survey/question', { id, config, questions, widgets })
  };
}

export function setSurvey(id, config, questions, widgets) {
  return {
    types: [SAVE2, SAVE2_SUCCESS, SAVE2_FAIL],
    promise: ({ client }) => client.post('/v1/survey/receive', { id, config, questions, widgets })
  };
}

export function setWidget(id, config, widgets) {
  return {
    types: [SAVE2, SAVE2_SUCCESS, SAVE2_FAIL],
    promise: ({ client }) => client.post('/v1/survey/receiveWidget', { id, config, widgets })
  };
}

export function delSurvey(id) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/remove?id=${id}`)
  };
}

export function trashSurvey(id) {
  return {
    types: [TRASH, TRASH_SUCCESS, TRASH_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/to-trash?id=${id}`)
  };
}
