const GET_MESSAGE = 'GET_MESSAGE';
const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
const GET_MESSAGE_FAIL = 'GET_MESSAGE_FAIL';
const GET_MESSAGE2 = 'GET_MESSAGE2';
const GET_MESSAGE2_SUCCESS = 'GET_MESSAGE2_SUCCESS';
const GET_MESSAGE2_FAIL = 'GET_MESSAGE2_FAIL';
const SET_MESSAGE = 'SET_MESSAGE';
const SET_MESSAGE_SUCCESS = 'SET_MESSAGE_SUCCESS';
const SET_MESSAGE_FAIL = 'SET_MESSAGE_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MESSAGE:
      return {
        ...state,
        loading: true
      };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case GET_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case GET_MESSAGE2_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data2: action.result
      };
    default:
      return state;
  }
}

export function getMessage(id = 0, surveyId = 0) {
  return {
    types: [GET_MESSAGE, GET_MESSAGE_SUCCESS, GET_MESSAGE_FAIL],
    promise: ({ client }) => client.get(`/v1/message?chatId=${id}&surveyId=${surveyId}&limit=1-10&innerLimit=1-1&subId=web`)
  };
}

export function getMessage2(id = 0, surveyId = 0) {
  return {
    types: [GET_MESSAGE2, GET_MESSAGE2_SUCCESS, GET_MESSAGE2_FAIL],
    promise: ({ client }) => client.get(`/v1/message?chatId=${id}&surveyId=${surveyId}&limit=1-10&innerLimit=1-10&subId=web`)
  };
}

export function setMessage(chatId, surveyId, title, recepient, msgText, dateTime) {
  return {
    types: [SET_MESSAGE, SET_MESSAGE_SUCCESS, SET_MESSAGE_FAIL],
    promise: ({ client }) => client.post('/v1/message', { chatId, surveyId, title, recepient, msgText, dateTime })
  };
}
