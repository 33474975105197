import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as LinkSlider } from 'react-scroll';
import ScrollToTop from 'react-scroll-up';
import Join from '../JoinModal/JoinModal';
import Modal from '../Modal/Modal';
import More from './More';

class Footer extends Component {
  static propTypes = {
    subId: PropTypes.string
  };
  state = {
    menuId: 'none',
    modal: false,
    modalMore: false
  };
  render() {
    const styles = require('./Footer.less');
    const logo = require('../../../static/images/logo/logo.svg');
    const logoAma = require('../../../static/images/logo-ama.png');
    const logoEsomar = require('../../../static/images/logo-esomar.png');
    return (
      <footer className={ styles.footer }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <div className={ styles.menuList }>
              <ul className={ styles.menu } style={{ width: '180px' }}>
                <li style={{ position: 'relative', height: '20px' }}><Link to="/" style={{ position: 'absolute', top: '-14px' }}><img src={ logo } width="144" height="46" /></Link></li>
                <li style={{ marginBottom: '0' }} />
                <li>Есть вопросы?</li>
                <li style={{ marginBottom: '22px' }}>+7 747 359 59 59</li>
                <li style={{ position: 'relative', height: '28px' }}>Звоните <a href="tel:+77473595959"><img src="./images/call.svg" width="26" height="26" style={{ position: 'absolute', top: '-6px', left: '80px' }} /></a></li>
                <li style={{ position: 'relative' }}>Пишите <a href="mailto:info@mvoter.com" target="_blank" rel="noreferrer"><img src="./images/email.png" width="26" height="26" style={{ position: 'absolute', top: '-6px', left: '80px' }} /></a></li>
              </ul>
              <ul className={ `${styles.menu} ${this.state.menuId === 'two' ? styles.hideItem : ''}` } style={{ width: '160px' }}>
                <li onClick={ () => this.setState({ menuId: 'two' }) }>О продукте</li>
                <li>{ this.props.subId === 'home' ? <LinkSlider to="link2" spy smooth offset={-50} duration={500}>О системе</LinkSlider> : <Link to="/?subId=link2">О системе</Link> }</li>
                <li>{ this.props.subId === 'home' ? <LinkSlider to="link1" spy smooth offset={-50} duration={500}>Отрасли</LinkSlider> : <Link to="/?subId=link1">Отрасли</Link> }</li>
                <li>{ this.props.subId === 'home' ? <LinkSlider to="link3" spy smooth offset={-50} duration={500}>Аналитика</LinkSlider> : <Link to="/?subId=link3">Аналитика</Link> }</li>
                <li><Link to="/integration">Интеграция</Link></li>
                <li>{ this.props.subId === 'home' ? <LinkSlider to="link6" spy smooth offset={-50} duration={500}>Контакты</LinkSlider> : <Link to="/?subId=link6">Контакты</Link> }</li>
              </ul>
              <ul className={ `${styles.menu} ${this.state.menuId === 'three' ? styles.hideItem : ''}` } style={{ width: '190px' }}>
                <li onClick={ () => this.setState({ menuId: 'three' }) }>Почему MVoter?</li>
                <li><Link to="/reliability">Надежность данных</Link></li>
                <li><Link to="/type-survey">Типы анкет</Link></li>
                <li><Link to="/call-center">Call центр</Link></li>
                <li><Link to="/mystery-shopping">Mystery Shopping</Link></li>
                <li><a onClick={ () => this.setState({ modalMore: !this.state.modalMore }) }>Подробнее</a></li>
                <li />
              </ul>
              <ul className={ `${styles.menu} ${this.state.menuId === 'four' ? styles.hideItem : ''}` } style={{ width: '160px' }}>
                <li onClick={ () => this.setState({ menuId: 'four' }) }>Цены</li>
                <li><Link to="/tariffs">Тарифы</Link></li>
                <li><Link to="/tariffs?subId=full">Обзор функции</Link></li>
                <li><a onClick={ () => this.setState({ modal: !this.state.modal }) }>Запросить демонстрацию</a></li>
                <li />
                <li />
              </ul>
              <ul className={ `${styles.menu} ${this.state.menuId === 'five' ? styles.hideItem : ''}` } style={{ width: '210px' }}>
                <li onClick={ () => this.setState({ menuId: 'five' }) }>База знаний</li>
                <li><Link to="/education">Обучение и внедрение</Link></li>
                <li><Link to="/faq">Поддержка</Link></li>
                <li><a href="./data/USER_AGREEMENT_MVOTER_080920.pdf" target="_blank" rel="noopener noreferrer">Пользовательское соглашение</a></li>
                <li><a href="./data/PRIVACY_POLICY_MVOTER_080920.pdf" target="_blank" rel="noopener noreferrer">Политика конфеденциальности</a></li>
                <li />
              </ul>
              <ul className={ `${styles.menu} ${this.state.menuId === 'sex' ? styles.hideItem : ''}` }>
                <li onClick={ () => this.setState({ menuId: 'sex' }) }>Членство в ассоциациях</li>
                <li><a href="https://www.esomar.org" target="_blank" rel="noreferrer"><img src={ logoEsomar } width="115" height="36" /></a></li>
                <li style={{ margin: '0' }} />
                <li />
                <li><a href="https://www.ama.org" target="_blank" rel="noreferrer"><img src={ logoAma } width="114" height="42" /></a></li>
              </ul>
            </div>
            <ul className={ styles.callMessage }>
              <li><a href="tel:77473595959"><img src="./images/call.svg" width="26" height="26" /></a></li>
              <li><a href="https://wa.me/77473595959" target="_blank" rel="noreferrer"><img src="./images/whatsapp.svg" width="26" height="26" /></a></li>
            </ul>
            <ul className={ styles.socMedia }>
              <li>
                <a target="_blank" href="https://www.instagram.com/mvoter_com" rel="noreferrer">
                  <img src="./images/instagram.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.tiktok.com/@mvoter_com" rel="noreferrer">
                  <img src="./images/tik-tok.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://api.whatsapp.com/send/?phone=77473595959&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D1%8F%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20MVoter" rel="noreferrer">
                  <img src="./images/whatsapp.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.youtube.com/@mvotertechnologies8939" rel="noreferrer">
                  <img src="./images/youtube.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=100085463405815" rel="noreferrer">
                  <img src="./images/facebook.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/company/mvoter-technologies/" rel="noreferrer">
                  <img src="./images/linkedin.png" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://hh.kz/vacancy/75620549?hhtmFrom=employer_vacancies" rel="noreferrer">
                  <img width={24} src="./images/hhwhite24.png" />
                </a>
              </li>
            </ul>
            <ScrollToTop showUnder={ 300 } style={{ zIndex: '7777', position: 'absolute' }}>
              <div className={ styles.arrowUp } onClick={ () => animateScroll.scrollToTop() } />
            </ScrollToTop>
            <h4>© 2023 MVoter. All rights reserved.</h4>
          </div>
        </div>
        { this.state.modal
          && (
            <Modal
              title="Запросить демонстрацию"
              content={ <Join onCloseClick={ () => this.setState({ modal: false }) } /> }
              onCloseClick={ () => this.setState({ modal: false }) }
            />
          )}
        { this.state.modalMore
          && (
            <Modal
            // title="Подробнее"
              styles={{ height: '95%', overflowY: 'scroll', width: '80%' }}
              content={ <More /> }
              onCloseClick={ () => this.setState({ modalMore: false }) }
            />
          )}
      </footer>
    );
  }
}

export default Footer;
